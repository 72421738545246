import TRTC from 'trtc-sdk-v5';
import { VirtualBackground } from 'trtc-sdk-v5/plugins/video-effect/virtual-background';
import { mapState, mapActions } from "vuex";
import util from "@/utils/util.js";
import { userAvatarByUserCode, userDataTypeByUserCode } from "@/api/home";
import { getMeetingTranslate, recordingScreenSharing } from "@/api/meeting";
export default {
  data() {
    return {
      trtc: null, // TRTC实例
      isMutedVideo: false, // 视频是否开启
      isMutedAudio: false, // 音频是否静音
      camStatus: 'stopped', // 摄像头状态
      micStatus: 'stopped', // 麦克风状态
      roomStatus: 'exited', // 房间状态
      shareStatus: 'stopped', // 投屏分享状态
      //音量
      voices: {
        me: 0,
      },
      hasShareStream: false,
      bigScreenId: '',
      avatars: {}, // 用户头像map
      remoteUsers: [],//远端用户列表
      virtualBackgroundEnabled: false,
    };
  },

  computed: {
    ...mapState({
      meetingInfo: (state) => state.meetingInfo,
    }),
    smallUserViews() {
      let peopleInfo = this.userLists.attendedTheMeeting
      // 远端小屏用户视图列表
      let small_user_views = []
      this.remoteUsers.forEach(user => {
        if (user.userId != this.meetingInfo.mainStreamUser && !user.hasShareScreen) {
          if (peopleInfo) {
            peopleInfo.forEach(people => {
              if (user.userId == people.userCode) {
                small_user_views.push({ ...user, userName: people.userName, avatarUrl: people.avatarUrl })
              }
            })
          } else {
            small_user_views.push({ ...user, userName: user.userId, avatarUrl: '' })
          }
        }
      })
      return small_user_views
    },
    bigScreenUser() {
      let peopleInfo = this.userLists.attendedTheMeeting
      let res = {};
      if (this.bigScreenId == this.userId) {//自己是大屏
        res = {
          hasVideo: !this.isMutedVideo,
          hasShareScreen: this.shareStatus == 'shared' ? true : false,
          userId: this.userId,
          userName: this.meetingInfo.businessDataDto.userName,
          avatarUrl: this.meetingInfo.businessDataDto.avatarUrl
        }
        return res
      }
      if (this.remoteUsers && this.remoteUsers.length > 0) {
        this.remoteUsers.forEach(user => {
          if (user.userId === this.bigScreenId) {
            if (peopleInfo) {
              peopleInfo.forEach(people => {
                if (user.userId == people.userCode) {
                  res = { ...user, userName: people.userName, avatarUrl: people.avatarUrl };
                }
              })
            } else {
              res = { ...user, userName: user.userId, avatarUrl: '' }
            }
          }
        })
        return res
      } else {
        res = { userName: '该成员暂无进入课程' }
        return res
      }
    }
  },
  watch: {
    'meetingInfo.mainStreamUser'(newId, oldId) {
      // console.log('新大屏：' + newId + ',旧大屏：' + oldId)
      // 翻译：切换主屏 禁言原主屏 开启新主屏
      if (this.meetingInfo.dataType == 101) {
        if (!this.trtc) { return }
        if (oldId) {
          // console.log('当前账户本人是翻译：禁言' + oldId + '开启' + newId)
          this.trtc.muteRemoteAudio(oldId, true);
        }
        if (newId) {
          // console.log('当前账户本人是翻译：禁言' + oldId + '开启' + newId)
          this.trtc.muteRemoteAudio(newId, false);
        }
      }
      //处理新大屏
      try {
        this.switchToBigScreen(newId);
        //处理老大屏
        if (oldId == this.userId) {
          // console.log('处理老大屏：当前账户本人是主持人，普通用户或者助教 ：不需要任何操作')
          // trtc.muteRemoteAudio(newId,true)
          this.stopASR(); //关闭语音转写
          this.trtc.stopLocalVideo()
            .then(() => {
              this.trtc.startLocalVideo({
                view: 'local_small',
                mute: this.isMutedVideo,
                option: {
                  mirror: false,
                  fillMode: 'contain'
                }
              })
            })
          // 音频设置
        } else {
          //  trtc.muteRemoteAudio(oldId,false)
          if (this.trtc) {
            this.trtc.stopRemoteVideo({ userId: oldId, streamType: TRTC.TYPE.STREAM_TYPE_MAIN })
              .then(() => {
                this.trtc.startRemoteVideo({
                  userId: oldId,
                  streamType: TRTC.TYPE.STREAM_TYPE_MAIN,
                  view: oldId + '_small_main',
                  option: {
                    small: true,
                    receiveWhenViewVisible: true, // 开启【只订阅可视区域的视频流】功能
                    viewRoot: document.getElementById('view-list'), // 传入所有 view list 的第一级父级元素，用于计算 view 和 viewRoot 的参考关系，判断 view 是否处于可视状态。默认值是 document.body
                    fillMode: 'contain'
                  }
                })
              })
          }
        }
      } catch (error) {

      }
    }
  },
  methods: {
    ...mapActions(["setAudioVolume"]),
    // 初始化TRTC实例
    initTRTC() {
      if (this.trtc) return;
      this.trtc = TRTC.create({ plugins: [VirtualBackground] });
      // this.addSuccessLog('trtc instance created.');
    },
    // 进入房间
    async enterRoom() {
      this.roomStatus = 'entering';
      this.initTRTC();
      try { // 进入房间操作
        await this.trtc.enterRoom({
          strRoomId: this.roomId,
          sdkAppId: parseInt(this.sdkAppId, 10),
          userId: this.userId,
          userSig: this.userSig,
          autoReceiveVideo: true
        });
        this.roomStatus = 'entered';

        this.installEventHandlers();
        this.startGetAudioLevel();

        this.reportSuccessEvent('enterRoom');
        this.addSuccessLog(`Enter room [${this.roomId}] success.`);
      } catch (error) { // 进入房间失败处理
        this.roomStatus = 'exited';
        this.addFailedLog(`Enter room ${this.roomId} failed, please check your params. Error: ${error.message}`);
        this.reportFailedEvent('enterRoom', error);
        throw error;
      }
    },
    // 启动本地音频
    async handleStartLocalAudio() {
      // 启动本地音频操作
      this.micStatus = 'starting';
      this.initTRTC();
      try {
        if (this.meetingInfo.dataType != 2) {
          this.isMutedAudio = true;
        } else {
          this.isMutedAudio = false;
        }
        await this.trtc.startLocalAudio({
          mute: this.isMutedAudio,
          option: {
            microphoneId: this.microphoneId,
          },
        });
        this.micStatus = 'started';
        if (this.meetingInfo.isMainStream == 1 || this.meetingInfo.dataType == 101) {
          this.startLocalUserASR(); //本地用户是主流、翻译，开启语音转写传给后台
        }
        this.addSuccessLog('Local audio started successfully');
        this.reportSuccessEvent('startLocalAudio');
      } catch (error) {
        this.micStatus = 'stopped';
        this.addFailedLog(`Local audio is failed to started. Error: ${error.message}`);
        this.reportFailedEvent('startLocalAudio', error.message);
        throw error;
      }
    },
    // 停止本地音频
    async handleStopLocalAudio() {
      // 停止本地音频操作
      if (this.micStatus !== 'started') {
        this.addFailedLog('The audio has not been started');
        return;
      }
      this.micStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalAudio();
        this.micStatus = 'stopped';
        this.addSuccessLog('Local audio stopped successfully');
        this.reportSuccessEvent('stopLocalAudio');
      } catch (error) {
        this.micStatus = 'started';
        this.addFailedLog(`Local audio is failed to stopped. Error: ${error.message}`);
        this.reportFailedEvent('stopLocalAudio', error.message);
        throw error;
      }
    },
    // 启动本地视频
    async handleStartLocalVideo() {
      // 启动本地视频操作
      this.camStatus = 'starting';
      this.initTRTC();
      try {
        let viewOption = 'local_small';
        if (this.userId === this.meetingInfo.mainStreamUser) { //本地视频是主屏，大屏id设置为本地用户id
          this.bigScreenId = this.userId;
          viewOption = 'big_main'
        }
        if (this.meetingInfo.dataType != 2) {
          this.isMutedVideo = true;
        } else {
          this.isMutedVideo = false;
        }
        await this.trtc.startLocalVideo({
          view: viewOption,
          option: {
            cameraId: this.cameraId,
            profile: '720p',
            mirror: false,
            fillMode: 'contain'
          },
          mute: this.isMutedVideo
        });
        this.camStatus = 'started';
        this.addSuccessLog('Local video started successfully');
        this.reportSuccessEvent('startLocalVideo');
      } catch (error) {
        this.camStatus = 'stopped';
        this.addFailedLog(`Local video is failed to started. Error: ${error.message}`);
        // this.reportFailedEvent('startLocalVideo', error.message);
        throw error;
      }
    },
    // 停止本地视频
    async handleStopLocalVideo() {
      // 停止本地视频操作
      if (this.camStatus !== 'started') {
        this.addFailedLog('The video has not been started');
        return;
      }
      this.camStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalVideo();
        this.camStatus = 'stopped';
        this.addSuccessLog('Local audio stopped successfully');
        this.reportSuccessEvent('stopLocalVideo');
      } catch (error) {
        this.camStatus = 'started';
        this.addFailedLog(`Local audio is failed to stopped. Error: ${error.message}`);
        this.reportFailedEvent('stopLocalVideo', error.message);
        throw error;
      }
    },
    // 退出房间
    async exitRoom() {
      if (this.roomStatus !== 'entered') {
        this.addFailedLog('The room has not been entered');
        return;
      }
      this.roomStatus = 'exiting';
      this.stopGetAudioLevel();
      try {
        await this.trtc.exitRoom();
        this.roomStatus = 'exited';
        this.bigScreenId = '';
        this.remoteUsers = [];
        this.uninstallEventHandlers();
        this.addSuccessLog('Exit room success.');
        this.reportSuccessEvent('exitRoom');
      } catch (error) {
        this.roomStatus = 'entered';
        this.addFailedLog(`Exit room failed. Error: ${error.message}`);
        this.reportFailedEvent('exitRoom', error);
        throw error;
      }

      if (this.micStatus === 'started') this.handleStopLocalAudio();
      if (this.camStatus === 'started') this.handleStopLocalVideo();
      if (this.shareStatus === 'shared') this.handleStopScreenShare();
    },
    // 静音视频
    async muteVideo() {
      try {
        await this.trtc.updateLocalVideo({
          mute: true,
          option: { fillMode: 'contain' }
        });
        this.isMutedVideo = true;
        this.camStatus = 'stopped'
        this.addSuccessLog('Mute video success.');
      } catch (error) {
        this.addFailedLog(`Mute video error: ${error.message}`);
      }
    },
    // 静音音频
    async muteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: true });
        this.isMutedAudio = true;
        this.micStatus = 'stopped'
        this.addSuccessLog('Mute audio success.');
      } catch (error) {
        this.addFailedLog(`Mute audio error: ${error.message}`);
      }
    },
    // 取消静音视频
    async unmuteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: false, option: { fillMode: 'contain' } });
        this.isMutedVideo = false;
        this.camStatus = 'started'
        this.addSuccessLog('Unmute video success.');
      } catch (error) {
        this.addFailedLog(`Unmute video error: ${error.message}`);
      }
    },
    // 取消静音音频
    async unmuteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: false });
        this.isMutedAudio = false;
        this.micStatus = 'started'
        this.addSuccessLog('Unmute audio success.');
      } catch (error) {
        this.addFailedLog(`Unmute audio error: ${error.message}`);
      }
    },
    // 切换设备
    async switchDevice(type, deviceId) {
      try {
        if (type === 'speak') {
          this.setCurrentSpeaker(deviceId)
          this.addSuccessLog(`Switch ${type} device success.`);
        }
        if (type === 'video' && this.camStatus === 'started') {
          await this.trtc.updateLocalVideo({
            option: {
              cameraId: deviceId,
              fillMode: 'contain'
            },
          });
          this.addSuccessLog(`Switch ${type} device success.`);
        }
        if (type === 'audio' && this.micStatus === 'started') {
          await this.trtc.updateLocalAudio({
            option: { microphoneId: deviceId },
          });
          this.addSuccessLog(`Switch ${type} device success.`);
        }
      } catch (error) {
        this.addFailedLog(`Switch ${type} device failed.`);
      }
    },
    // 开始获取音频级别
    startGetAudioLevel() {
      this.trtc.on(TRTC.EVENT.AUDIO_VOLUME, (event) => {
        event.result.forEach(({ userId, volume }) => {
          const isMe = userId === ''; //当 userId 为空串时，代表本地麦克风音量。
          if (isMe) {
            // console.log(`my volume: ${volume}`);
            this.voices.me = volume;
            this.setAudioVolume(volume);
          } else {
            this.voices[`${userId}`] = volume;
            // console.log(`user: ${userId} volume: ${volume}`);
          }
        });
      });
      this.trtc.enableAudioVolumeEvaluation(2000);
    },
    // 停止获取音频级别
    stopGetAudioLevel() {
      this.trtc && this.trtc.enableAudioVolumeEvaluation(-1);
    },
    // 安装事件处理程序
    installEventHandlers() {
      this.trtc.on(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.on(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable);
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.on(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
    },
    // 卸载事件处理程序
    uninstallEventHandlers() {
      this.trtc.off(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.off(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.off(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
    },
    // 处理错误
    handleError(error) {
      this.addFailedLog(`Local error: ${error.message}`);
      alert(error);
    },
    // 处理本地用户被踢出
    async handleKickedOut(event) {
      this.addFailedLog(`User has been kicked out for ${event.reason}`);
      this.trtc = null;
      await this.exitRoom();
    },
    // 处理远程用户进入房间
    async handleRemoteUserEnter(event) {
      const { userId } = event;
      if (userId == 'tourist') {
        return
      }
      // console.log("进房人" + userId)

      if (userId == this.meetingInfo.mainStreamUser) {
        this.bigScreenId = userId;
      }
      // userAvatarByUserCode({ userCode: userId }).then(res => {
      //   this.avatars[userId] = res
      // })
      const remoteUserIds = this.remoteUsers.map(user => user.userId);
      if (!remoteUserIds.includes(userId)) {
        this.remoteUsers.push({
          userId: userId,
          hasVideo: false,
          hasAudio: false,
          hasShareScreen: false,

        })
      }
      // 主持人 进房 禁言翻译
      if (this.meetingInfo.dataType == 2) {
        // console.log('当前账户是主持人：静音翻译');
        //  对比所有翻译列表 allTranslateList
        let foundUser = this.allTranslateList.find(user => user.userCode == userId && user.dataType == 101);
        if (foundUser) {
          // console.log('当前为主持人：静音翻译' + userId);
          await this.trtc.muteRemoteAudio(userId, true);
        }
      }
      // 在翻译用户进入房间时，只订阅主屏用户的音频流,关掉除本人其他翻译声音（和成员声音）
      if (this.meetingInfo.dataType == 101) {
        // console.log('当前账户是翻译：只订阅主屏用户的音频流,关掉除本人其他翻译声音');
        if (userId != this.bigScreenId) {
          // console.log('当前账户是翻译：静音' + userId);
          await this.trtc.muteRemoteAudio(userId, true);
        } else {
          // console.log('当前账户是翻译：' + userId + '是主流，不需要静音');
        }
      }
      // 在成员用户进入房间时,禁止翻译
      if (this.meetingInfo.dataType == 0 || this.meetingInfo.dataType == 1 || this.meetingInfo.dataType == 401) {
        // console.log('当前账户是成员或者助教，禁止翻译声音');
        let foundUser = this.allTranslateList.find(user => user.userCode == userId && user.dataType == 101);
        if (foundUser) {
          // console.log('当前为成员或者助教：静音翻译' + userId);
          await this.trtc.muteRemoteAudio(userId, true);
        }
      }
      // console.log(userId + '：进入房间-------->');
      // console.log('this.remoteUsers', this.remoteUsers);
      this.addSuccessLog(`Remote User [${userId}] entered`);
    },
    // 处理远程用户退出房间
    handleRemoteUserExit(event) {
      const { userId } = event;
      if (userId == this.meetingInfo.mainStreamUser) {
        this.bigScreenId = '';
      }
      let clone_remoteUsers = JSON.parse(JSON.stringify(this.remoteUsers));
      this.remoteUsers = clone_remoteUsers.filter(user => user.userId != userId); //删除退出用户
      this.addSuccessLog(`Remote User [${event.userId}] exit`);
    },
    // 处理远程视频可用
    handleRemoteVideoAvailable(event) {
      const { userId, streamType } = event;
      try {
        this.addSuccessLog(`[${userId}] [${streamType}] video available`);
        let hasVideo = false;
        let hasShareScreen = false;
        this.remoteUsers.forEach(user => {//初始化hasVideo、hasShareScreen
          if (user.userId == userId) {
            if (user.hasVideo) {
              hasVideo = true
            }
            if (user.hasShareScreen) {
              hasShareScreen = true
            }
          }
        })
        if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
          hasVideo = true;
          if (userId === this.meetingInfo.mainStreamUser) { //大屏用户
            this.$nextTick(async () => {
              await this.trtc.startRemoteVideo({
                userId, streamType, view: 'big_main',
                option: {
                  fillMode: 'contain'
                }
              });
            });
          } else { //小屏流
            this.$nextTick(async () => {
              await this.trtc.startRemoteVideo({
                userId,
                streamType,
                view: `${userId}_small_main`,
                option: {
                  small: true,
                  receiveWhenViewVisible: true, // 开启【只订阅可视区域的视频流】功能
                  viewRoot: document.getElementById('view-list'), // 传入所有 view list 的第一级父级元素，用于计算 view 和 viewRoot 的参考关系，判断 view 是否处于可视状态。默认值是 document.body
                  fillMode: 'contain'
                }
              });
            });
          }
        } else {
          hasShareScreen = true;
          this.hasShareStream = true;
          // 开始投屏，判断是否录制中
          // if (this.isRecording) {
          //   this.stopSwitch();
          // }
          this.$nextTick(async () => {
            await this.trtc.stopRemoteVideo({ userId: this.bigScreenId, streamType: TRTC.TYPE.STREAM_TYPE_MAIN });
            await this.trtc.startRemoteVideo({
              userId, streamType, view: 'share_main',
              option: {
                fillMode: 'contain'
              }
            });
          });
        }
        const clone_remoteUsers = JSON.parse(JSON.stringify(this.remoteUsers));
        clone_remoteUsers.forEach(user => {
          if (user.userId == userId) {
            user.hasVideo = hasVideo;
            user.hasShareScreen = hasShareScreen;
          }
        })
        this.remoteUsers = clone_remoteUsers;
        this.addSuccessLog(`Play remote video success: [${userId}]`);
      } catch (error) {
        this.addFailedLog(`Play remote video failed: [${userId}], error: ${error.message}`);
      }
    },
    // 处理远程视频不可用
    handleRemoteVideoUnavailable(event) {
      const { userId, streamType } = event;
      this.addSuccessLog(`[${userId}] [${streamType}] video unavailable`);
      // Display user avatar when video is unavailable
      this.trtc.stopRemoteVideo({ userId, streamType });
      if (streamType !== TRTC.TYPE.STREAM_TYPE_MAIN) {
        this.hasShareStream = false;
        this.$nextTick(async () => {
          await this.trtc.startRemoteVideo({
            userId: this.bigScreenId,
            streamType: TRTC.TYPE.STREAM_TYPE_MAIN,
            view: 'big_main',
            option: {
              fillMode: 'contain'
            }
          });
        })
      }
      const clone_remoteUsers = JSON.parse(JSON.stringify(this.remoteUsers));
      clone_remoteUsers.forEach(user => {
        if (user.userId == userId) {
          if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
            user.hasVideo = false;
          } else {
            user.hasShareScreen = false;
            // this.hasShareStream = false;
            // 结束投屏，判断是否录制中
            // if (this.isRecording) {
            //   this.stopSwitch();
            // }
          }
        }
      })
      this.remoteUsers = clone_remoteUsers;
    },
    // 处理远程音频可用
    handleRemoteAudioAvailable(event) {
      const { userId } = event;
      const clone_remoteUsers = JSON.parse(JSON.stringify(this.remoteUsers));
      clone_remoteUsers.forEach(user => {
        if (user.userId == userId) {
          user.hasAudio = true;
        }
      })
      this.remoteUsers = clone_remoteUsers;
      this.addSuccessLog(`[${event.userId}] audio available`);
    },
    // 处理远程音频不可用
    handleRemoteAudioUnavailable(event) {
      const { userId } = event;
      const clone_remoteUsers = JSON.parse(JSON.stringify(this.remoteUsers));
      clone_remoteUsers.forEach(user => {
        if (user.userId == userId) {
          user.hasAudio = false;
        }
      })
      this.remoteUsers = clone_remoteUsers;
      this.addSuccessLog(`[${event.userId}] audio unavailable`);
    },
    // 本地屏幕分享停止事件通知，仅对本地屏幕分享流有效。
    handleScreenShareStopped() {
      this.hasShareStream = false;
      this.shareStatus = 'stopped';
      let data = {
        meetingId: this.meetingId,
        mediaId: 0, //0 主流 1 辅流
      };
      recordingScreenSharing(data).then(res => { })
      this.addSuccessLog('Stop share screen success');
    },
    // 设置扬声器声音
    setSpeakerVolume(volume) {
      // console.log('volumevolumevolume', volume)
      // 音量大小，取值范围为0 - 100，默认值为 100。
      // https://web.sdk.qcloud.com/trtc/webrtc/v5/doc/zh-cn/TRTC.html#setRemoteAudioVolume
      // 远端用户 userId。'*' 代表所有远端用户。范围0 - 100
      this.trtc.setRemoteAudioVolume('*', volume * 100);
    },
    async switchToBigScreen(userId) {
      try {
        this.bigScreenId = userId;
        this.$nextTick(async () => {
          if (userId == this.userId) {// 本地用户设置为大屏
            if (this.trtc) {
              this.trtc.stopLocalVideo && await this.trtc.stopLocalVideo();
              this.trtc.startLocalVideo && await this.trtc.startLocalVideo({
                view: 'big_main', mute: this.isMutedVideo,
                option: {
                  mirror: false,
                  fillMode: 'contain'
                }
              });
            }
            this.startLocalUserASR();//本地用户被设置成为大屏，开启本地语音转写
            // 新主屏音频设置
            // 主持人 切为主屏 禁言翻译
            // 普通用户 （助教）禁言翻译
            if (this.meetingInfo.dataType == 2 || this.meetingInfo.dataType == 0 || this.meetingInfo.dataType == 1) {
              try {
                // console.log('处理新大屏：主持人、普通用户 （助教），禁言翻译')
                // 获取在线翻译列表
                let res = await getMeetingTranslate({ MeetingId: this.meetingId });
                let translateList = res.translateMeeting;
                // 翻译静音
                if (translateList && translateList.length > 0) {
                  translateList.forEach((val) => {
                    if (this.trtc) {
                      // console.log('处理新大屏：禁言翻译为' + val.userCode)
                      this.trtc.muteRemoteAudio(val.userCode, true);
                    }
                  })
                }
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            if (this.trtc) {
              this.trtc.stopRemoteVideo && await this.trtc.stopRemoteVideo({ userId, streamType: TRTC.TYPE.STREAM_TYPE_MAIN });
              // this.trtc.startRemoteVideo && await this.trtc.startRemoteVideo({ userId, streamType: TRTC.TYPE.STREAM_TYPE_MAIN, view: 'big_main' });
            }
          }
        })
      } catch (error) {
      }
    },
    // 开启虚拟背景插件
    async handleStartVirtualBackground(type, src) {
      this.initTRTC()
      // console.log(this.camStatus, 'this.camStatus ')
      this.camStatus = 'started';
      if (this.camStatus === 'started') {
        // Camera is already started
        // console.log('Camera is started');
        await this.trtc.startPlugin('VirtualBackground', {
          sdkAppId: parseInt(this.sdkAppId, 10),
          userId: this.userId,
          userSig: this.userSig,
          type: type,
          src: src
        });
      } else {
        // console.log(' Camera is not started');
      }

    },
    // 更新参数
    async updateVirtualBackground(type, src) {
      await this.trtc.updatePlugin('VirtualBackground', {
        type: type,
        src: src
      });
    },
    // 关闭虚拟背景
    async disableVirtualBackground() {
      await this.trtc.stopPlugin('VirtualBackground');
    },

  }

};

