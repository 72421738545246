<template>
  <el-drawer
    :modal="false"
    :visible.sync="showChat"
    :wrapperClosable="false"
    :title="$t('text.chat')"
    direction="rtl"
    class="chat"
    :before-close="handleClose"
  >
    <div class="content" id="transContainer">
      <ul>
        <li v-for="(item, index) in chatList" :key="index">
          <p
            :style="{
              color:
                item.userCode == $store.state.userData.userCode
                  ? '#eb2f96'
                  : '#2db7f5',
            }"
          >
            {{ item.name }}
          </p>
          <p>{{ item.text }}</p>
        </li>
      </ul>
      <!-- </template>
      <div class="loading" v-else>
        <img src="@/assets/meeting-empty.png" alt="" />
        <p>暂无聊天信息...</p>
      </div> -->
    </div>
    <div class="bottom">
      <!-- <el-input
        placeholder="请输入"
        v-model="chatMsg"
        class="input-with-select"
        @change="handleSend"
        @blur="handleBlur"
      > -->
      <input
        type="text"
        placeholder="请输入"
        v-model="chatMsg"
        @keydown.enter="handleSend"
      />
      <el-button
        slot="append"
        icon="el-icon-s-promotion"
        style="font-size: 26px"
        :loading="sendLoading"
        @click="handleSend"
      ></el-button>
      <!-- </el-input> -->
    </div>
  </el-drawer>
</template>
<script>
import { computed } from "vue";
// import { addSuffix } from '@/utils/util.js'
import util from "@/utils/util.js";

import { meetingChatList } from "@/api/meeting";
import i18n from "../../locales/index.js";

const initArr = [
  {
    phoneticTranscriptionType: "16k_zh",
    phoneticTranscriptionTypeName: "无翻译",
  },
];

export default {
  props: {
    chatObj: Object,
    meetingId: String,
  },
  data() {
    return {
      showChat: false,
      chatMsg: "",
      sendLoading: false,
      chatList: [],
    };
  },
  watch: {
    chatObj: {
      handler(newVal, oldVal) {
        this.chatList.push({
          text: newVal.msg,
          name: newVal.userName,
          userCode: newVal.userCode,
        });
      },
      deep: true,
    },
    chatList: {
      handler(newVal, oldVal) {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
  created() {
    console.log(this.$store.state.userData.name);
  },
  methods: {
    onShow() {
      this.showChat = true;
      this.getListFun();
    },
    clone(obj) {
      let objClone = JSON.parse(JSON.stringify(obj));
      return objClone;
    },
    getListFun() {
      this.chatList = [];
      meetingChatList({ MeetingId: this.meetingId }).then((res) => {
        this.chatList = this.chatList.concat(res);
      });
    },
    handleSend() {
      if (this.chatMsg.trim()) {
        this.$emit("okSend", this.chatMsg);
        this.chatMsg = "";
        this.sendLoading = false;
      }
    },
    handleClose(done) {
      this.chatList = [];
      this.showChat = false;
      // this.$confirm(i18n.tc('text.DoTurnOffSpeechTransliteration'))
      //   .then((_) => {
      //     // this.$emit('closeOk')
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 滚动到底
    scrollToBottom() {
      this.$nextTick(() => {
        var scrollableDiv = document.getElementById("transContainer");
        if (scrollableDiv) {
          scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 0 0;
}
.chat {
  bottom: unset;
  left: unset;
  width: 33%;
  height: 100%;
  /deep/.el-drawer {
    width: 100% !important;
    background: #1f2022;
    .el-drawer__header {
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .el-drawer__body {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
  .top {
    width: 30%;
    height: 80px;
    background: #1f2022;
    position: fixed;
    > p {
      margin-top: 16px;
      color: #ffffffa3;
      font-size: 14px;
    }
  }
  .select-con {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.12);

    > p {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.12);
      font-size: 16px;
      color: #fff;
    }
    /deep/.el-select {
      flex: 1;
      .el-input__inner {
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .content {
    width: 100%;
    height: 90%;
    // border: 1px solid #fff;
    box-sizing: border-box;
    overflow-y: scroll;
    ul {
      width: 100%;
      > li {
        font-size: 16px;
        margin-bottom: 12px;

        > p:nth-child(1) {
          color: #2db7f5;
          margin-bottom: 8px;
        }
        > p:nth-child(2) {
          color: rgba(255, 255, 255, 0.76);
        }
      }
    }
    /deep/.el-timeline {
      overflow: hidden;
    }
    /deep/.el-timeline-item__node {
      background-color: #9197a6;
    }
    /deep/.el-timeline-item__tail {
      border-left: 0.01616rem solid #ffffff29;
    }
    /deep/.el-timeline-item__timestamp {
      color: #ffffff7a;
      font-size: 14px;
    }
    /deep/.el-timeline-item__content {
      color: #fff;
    }
  }
  .bottom {
    position: fixed;
    bottom: 0;
    left: 70%;
    width: 30%;
    height: 74px;
    padding-right: 2%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      width: 268px;
      height: 40px;
      padding: 0 15px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      font-size: 16px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    /deep/.el-button {
      height: 40px;
      padding: 0;
      width: 70px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .loading {
    width: 100%;
    text-align: center;
    > img {
      width: 80%;
    }
    > p {
      color: #939bb0;
      font-size: 16px;
      margin-top: -50px;
    }
  }
}
</style>