<template>
  <div class="meeting-room">
    <el-row style="height: 100%">
      <el-col class="meeting-main">
        <div class="flex-container">
          <div
            class="float-box"
            v-if="shareStatus == 'shared'"
            @click="closeShareVideo"
          >
            {{ $t("text.cancelProjectionScreen") }}
            <i class="el-icon-close"></i>
          </div>
          <div class="float-box" v-if="isRecording">
            {{ $t("text.recording") }}
          </div>
        </div>
        <div
          class="meeting-stream"
          :style="{ height: !token ? '100%' : 'calc(100% - 60px)' }"
        >
            <div class="left-bg"  :style="{width:unfoldStatus?'66%':'100%'}">
              <!-- 大屏播放流 -->
              <div class="big-stream-container">
                <!-- <div v-show="shareStatus == 'shared'">屏幕分享中</div> -->
                <!-- shareStatus != 'shared' && -->
                <!-- || bigScreenUser.hasShareScreen -->
                <div
                  v-show="
                    bigScreenId && bigScreenUser.hasVideo && !hasShareStream
                  "
                  id="big_main"
                  :class="[
                    'main-stream',
                    meetingInfo.dataType == 401
                      ? 'main-stream-tourist-size'
                      : 'main-stream-size',
                  ]"
                  ref="big_main"
                  :style="{width:unfoldStatus?'66%':'100%'}"
                >
                  <div class="userId">{{ bigScreenUser.userName }}</div>
                </div>
                <!-- 投屏 -->
                <div
                  v-show="hasShareStream"
                  id="share_main"
                  :class="[
                    'main-stream',
                    meetingInfo.dataType == 401
                      ? 'main-stream-tourist-size'
                      : 'main-stream-size'
                  ]"
                  ref="share_main"
                  :style="{width:unfoldStatus?'66%':'100%'}"
                >
                  <div class="userId">{{ bigScreenUser.userName }}</div>
                </div>
                <div
                  v-show="
                    shareStatus != 'shared' &&
                    !bigScreenUser.hasVideo &&
                    !bigScreenUser.hasShareScreen
                  "
                  :class="[
                    'main-stream',
                    meetingInfo.dataType == 401
                      ? 'main-stream-tourist-size'
                      : 'main-stream-size',
                  ]"
                   :style="{width:unfoldStatus?'66%':'100%'}"
                >
                  <div class="main-stream-user">
                    <img
                      v-if="this.meetingInfo.mainStreamUser == this.userId"
                      :src="
                        $store.state.userData.avatarUrl ||
                        require('../assets/user.png')
                      "
                      alt=""
                      class="userAvatar"
                    />
                    <img
                      v-else
                      :src="
                        bigScreenUser.avatarUrl || require('../assets/user.png')
                      "
                      alt=""
                      class="userAvatar"
                    />
                    <p class="userId">{{ bigScreenUser.userName }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-bg" :style="{width:unfoldStatus?'34%':'0'}">
              <div class="operationBtn" @click="handleUnfold">
                <img src="@/assets/icon/packUp.png" class="packUp" v-if="unfoldStatus" />
                <img src="@/assets/icon/unfold.png" class="unfold" v-else />

              </div>
              <div class="video-container" id="view-list">
                <!-- 本人小画面 -->
                <template v-if="meetingInfo.dataType != 401">
                  <div v-if="bigScreenId != userId" class="local-small">
                    <div id="local_small" class="local-small-con">
                      <div class="user-info" v-if="isMutedVideo">
                        <img
                          :src="
                            $store.state.userData.avatarUrl ||
                            require('../assets/user.png')
                          "
                          alt=""
                          class="userAvatar"
                        />
                        <span class="userId">{{
                          $store.state.userData.name
                        }}</span>
                      </div>
                      <div v-else class="userId">
                        {{ $store.state.userData.name }}
                      </div>
                    </div>
                  </div>
                </template>
                <!-- 远程用户小画面 -->
                <div
                  class="remote-container"
                  v-for="item in smallUserViews"
                  :key="item.userId"
                >
                  <div :id="item.userId + '_small_main'" class="remote-stream">
                    <div class="remote-stream-user" v-if="!item.hasVideo">
                      <img
                        :src="item.avatarUrl || require('../assets/user.png')"
                        alt=""
                        class="userAvatar"
                      />
                      <span class="userId">{{ item.userName }}</span>
                    </div>
                    <span v-else class="userId">{{ item.userName }}</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- 底部区域 -->
        <div class="meeting-footer" v-if="token">
          <ul>
            <li @click="volumeSwitch">
              <audio-icon
                style="display: inline-block"
                :audio-volume="voices.me"
                :is-muted="isMutedAudio"
              >
              </audio-icon>
              <p>{{ $t("text.mute") }}</p>
            </li>
            <li @click="videoSwitch">
              <video-media-icon
                style="display: inline-block"
                :is-muted="isMutedVideo"
              >
              </video-media-icon>
              <p>{{ $t("text.camera") }}</p>
            </li>
            <li @click="shareSwitch">
              <img src="@/assets/icon/screen.png" alt="" />
              <p>{{ $t("text.projectionScreenSetting") }}</p>
            </li>
            <li
              @click="translateSwitch"
            >
              <img src="@/assets/icon/translate.png" alt="" />
              <p>{{ $t("text.realTimeTrans") }}</p>
            </li>
            <li
              @click="translateSwitch"
              v-if="meetingInfo.phoneticTranscriptionStatus"
            >
              <img src="@/assets/icon/translate.png" alt="" />
              <p>{{ $t("text.realTimeTrans") }}</p>
            </li>
            <li
              v-if="meetingInfo.dataType == 2 || meetingInfo.dataType == 1"
              @click="inviteSwitch"
            >
              <img src="@/assets/icon/invite.png" alt="" />
              <p>{{ $t("text.invite") }}</p>
            </li>
            <li @click="peopleSwitch">
              <img src="@/assets/icon/member.png" alt="" />
              <p>{{ $t("text.member") }}</p>
            </li>
            <li @click="chatSwitch">
              <img src="@/assets/icon/chat.png" alt="" />
              <p>{{ $t("text.chat") }}</p>
            </li>

            <!--  <el-popover trigger="click" v-if="!isRecording">
                <div>
                  <el-button @click="recordSwitch('480p')">480p</el-button>
                  <el-button @click="recordSwitch('720p')">720p</el-button>
                  <el-button @click="recordSwitch('1080p')">1080p</el-button>
                </div>
                <li slot="reference">
                  <img src="@/assets/icon/record.png" alt="" />
                  <p>录制</p>
                </li>
              </el-popover> -->
            <template
              v-if="meetingInfo.dataType == 2 || meetingInfo.dataType == 1"
            >
              <li slot="reference" @click="recordStart" v-if="!isRecording">
                <img src="@/assets/icon/record.png" alt="" />
                <p>{{ $t("text.record") }}</p>
              </li>
              <li @click="stopRecord" v-else>
                <img src="@/assets/icon/record.png" alt="" />
                <p>{{ $t("text.End") }}{{ $t("text.record") }}</p>
              </li>
            </template>
            <li @click="fileSwitch">
              <img src="@/assets/icon/file.png" alt="" />
              <p>{{ $t("text.file") }}</p>
            </li>
            <li
              @click="virtualBackgroundSwitch"
              v-if="meetingInfo.virtualBackgroundStatus == 1"
            >
              <img src="@/assets/icon/setting.png" alt="" />
              <p>{{ $t("text.backgroundSetting") }}</p>
            </li>
            <li @click="settingSwitch">
              <img src="@/assets/icon/setting.png" alt="" />
              <p>{{ $t("text.setting") }}</p>
            </li>
          </ul>
          <div class="footer_extra">
            <p class="duration">
              <img src="../assets/underway.png" />
              <span>{{ formattedTimestamp }}</span>
            </p>
            <el-button type="warning" size="small" @click="handleLeave">{{
              $t("text.leaveMeeting")
            }}</el-button
            >&nbsp;&nbsp;
            <!-- 主持人助教结束会议 -->
            <el-popconfirm
              :title="$t('text.confirm') + $t('text.concludeMeeting') + '?'"
              @confirm="handleEnd"
              v-if="meetingInfo.dataType == 1 || meetingInfo.dataType == 2"
            >
              <el-button slot="reference" type="danger" size="small">
                {{ $t("text.concludeMeeting") }}
              </el-button>
            </el-popconfirm>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-drawer
      :title="$t('text.memberSetting')"
      :visible.sync="showSidebar"
      direction="rtl"
      :before-close="handlePeopleClose"
      class="meeting-sidebar"
    >
      <div class="sidebar-content">
        <div class="sidebar-header">
          <el-input
            v-model="peopleKey"
            prefix-icon="el-icon-search"
            :placeholder="$t('text.search')"
            @change="handleSearch"
          >
          </el-input>
          <el-tabs
            v-if="userLists && !userLists.resultKey"
            style="padding: 10px"
            v-model="activepeople"
            type="card"
            :stretch="true"
          >
            <el-tab-pane :label="$t('text.joined')" name="first">
              <!-- 在这里添加已入会的人员列表 -->
            </el-tab-pane>
            <el-tab-pane :label="$t('text.notEnrolled')" name="second">
              <!-- 在这里添加未入会的人员列表 -->
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="person-list">
          <ul
            v-if="activepeople == 'first' && userLists && !userLists.resultKey"
          >
            <li
              :class="i.id == selecetedPeople ? 'person-list__selected' : ''"
              v-for="i in userLists.attendedTheMeeting"
              :key="i.id"
              @click="selectPerson(i)"
            >
              <div class="person-list__title">
                <img
                  :src="i.avatarUrl || require('../assets/user.png')"
                  alt=""
                />
                <div class="text">
                  <p>{{ i.userName }}</p>
                  <!-- 0 参会人员 1 会议助教 2 主持人 101 翻译 -->
                  <p
                    :class="[
                      'tag',
                      i.dataType == 2
                        ? ''
                        : i.dataType == 1
                        ? 'success'
                        : i.dataType == 0
                        ? 'info'
                        : i.dataType == 101
                        ? 'warning'
                        : 'danger',
                    ]"
                  >
                    {{
                      i.dataType == 2
                        ? $t("text.host")
                        : i.dataType == 1
                        ? $t("text.teachingAssistant")
                        : i.dataType == 0
                        ? $t("text.member")
                        : i.dataType == 101
                        ? $t("text.translate")
                        : $t("text.unknow")
                    }}
                  </p>
                </div>
              </div>
              <div style="color: #feffffb8" v-if="i.id != selecetedPeople">
                <audio-icon-user
                  style="display: inline-block"
                  :is-muted="i.voiceStatus == 0"
                ></audio-icon-user>
                <video-media-icon
                  style="display: inline-block"
                  :is-muted="i.cameraStatus == 0"
                ></video-media-icon>
              </div>
              <!-- (i.dataType == 1||i.dataType == 2) && -->
              <div v-if="i.id == selecetedPeople">
                <!-- 语音状态 0 禁止（默认） 1 允许 -->
                <p>
                  <el-button
                    type="text"
                    style="color: #ffffffa3"
                    @click="userControl('voice', i)"
                    >{{ i.voiceAuthority == 1 ? "关闭" : "开启"
                    }}{{ $t("text.voiceAuthority") }}</el-button
                  >
                  <el-button
                    type="text"
                    style="color: #ffffffa3"
                    @click="userControl('video', i)"
                    >{{ i.cameraAuthority == 1 ? "关闭" : "开启"
                    }}{{ $t("text.videoPermission") }}</el-button
                  >
                </p>
                <el-button
                  type="text"
                  style="color: #416fff"
                  @click="userControl('mainScreen', i)"
                  v-if="i.dataType != 101"
                  >{{ $t("text.setHomeScreen") }}</el-button
                >
                <!-- <el-button type="text" style="color: #ff3333" @click="kickUser"
                  >踢除</el-button
                > -->
              </div>
            </li>
          </ul>
          <ul
            v-else-if="
              activepeople == 'second' && userLists && !userLists.resultKey
            "
          >
            <li v-for="i in userLists.notAttendingTheMeeting" :key="i.id">
              <div class="person-list__title">
                <img
                  style="height: 48px"
                  :src="i.avatarUrl || require('../assets/user.png')"
                  alt=""
                />
                <div class="text">
                  <p>{{ i.userName }}</p>
                  <!-- 0 参会人员 1 会议助教 2 主持人 101 翻译 -->
                  <p
                    :class="[
                      'tag',
                      i.dataType == 2
                        ? ''
                        : i.dataType == 1
                        ? 'success'
                        : i.dataType == 0
                        ? 'info'
                        : i.dataType == 101
                        ? 'warning'
                        : 'danger',
                    ]"
                  >
                    {{
                      i.dataType == 2
                        ? "主持人"
                        : i.dataType == 1
                        ? "助教"
                        : i.dataType == 0
                        ? "成员"
                        : i.dataType == 101
                        ? "翻译"
                        : "未知"
                    }}
                  </p>
                </div>
              </div>
              <div style="color: #feffffb8; font-size: 12px">
                <span>未入会</span>
              </div>
            </li>
          </ul>
          <ul v-if="userLists && userLists.resultKey">
            <li v-for="i in userLists.resultKey" :key="i.id">
              <div class="person-list__title">
                <img src="@/assets/user.png" alt="" />
                <p class="text">
                  {{ i.userName }}
                  <br />
                  <span
                    v-if="i.dataType == 2"
                    style="font-size: 12px; color: #416fff"
                    >主持人</span
                  >
                </p>
              </div>
              <div style="color: #feffffb8; font-size: 12px">
                <span
                  >{{ i.isParticipationStatus == 1 ? "已入会" : "未入会" }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="sidebar-action">
        <el-button
          size="small"
          type="info"
          @click="inviteSwitch"
          v-if="meetingInfo.dataType == 2 || meetingInfo.dataType == 1"
          >{{ $t("text.invite") }}</el-button
        >
        <el-button
          v-if="meetingInfo.dataType == 2 || meetingInfo.dataType == 1"
          size="small"
          type="info"
          @click="handleAllMute"
          >{{ $t("text.allMute") }}</el-button
        >
        <!-- <el-button size="small" type="info">更多</el-button> -->
      </div>
    </el-drawer>
    <el-drawer
      :title="$t('text.file')"
      :visible.sync="showFileBar"
      direction="rtl"
      :before-close="handleFileClose"
      class="meeting-filebar"
    >
      <div class="filebar-content">
        <!-- <div class="filebar-header">
          <el-input prefix-icon="el-icon-search" placeholder="搜索" clearable>
          </el-input>
        </div> -->

        <div class="file-list">
          <ul v-if="FileList && FileList.length > 0">
            <li v-for="i in FileList" :key="i.id">
              <div class="file-list__title">
                <img style="height: 48px" src="@/assets/file.png" alt="" />
                <p class="text">
                  {{ i.name }}
                </p>
              </div>
              <div style="color: #416fff; font-size: 12px; cursor: pointer">
                <span @click="downloadFile(i)">{{ $t("text.download") }}</span>
              </div>
            </li>
          </ul>
          <div class="loading" v-else>
            <img src="@/assets/meeting-empty.png" alt="" />
            <p>{{ $t("text.noFile") }}</p>
          </div>
        </div>
      </div>
    </el-drawer>
    <invite-person ref="inviteDialog"></invite-person>
    <BackgroundSetting
      ref="virtualBackground"
      :meetingId="meetingId"
      @change="handleSettingBg"
    ></BackgroundSetting>
    <setting ref="setDialog" @setSpeakerVolume="handleSpeakerVolume"
    @changeSetMic="changeSetMic" @changeSetCamera="changeSetCamera"></setting>
    <translate
      ref="translate"
      :mainStreamUser="meetingInfo.mainStreamUser"
      :meetingMinutesStatus="meetingInfo.meetingMinutesStatus"
      :meetingId="meetingId"
      @langOk="handleLang"
      :inAWordObj="inAWordObj"
      :inAWordASR="inAWordASR"
      :dataType="meetingInfo.dataType"
      :isMainStream="meetingInfo.isMainStream"
      :phoneticTranscriptionType="meetingInfo.phoneticTranscriptionType"
      @onMeeting="onShowMeeting"
    ></translate>
    <el-dialog
      :title="$t('text.DownloadMinutes')"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="download-meeting">
        <p>{{ $t("text.downloadFormat") }}</p>
        <el-radio-group v-model="exportType">
          <el-radio-button
            v-for="item in docType"
            :key="item.type"
            :label="item.type"
            >{{ item.name }}</el-radio-button
          >
        </el-radio-group>
        <p style="margin-top: 20px">{{ $t("text.DownloadLanguage") }}</p>
        <el-radio-group v-model="languageType">
          <el-radio-button
            v-for="item in meetingTranslators"
            :key="item.languageType"
            :label="item.languageType"
            >{{ item.languageTypeName }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">{{ $t("text.cancel") }}</el-button>
        <el-button type="primary" @click="handleDownLoad">{{
          $t("text.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <chat ref="chat" @okSend="handleSend" 
    :chatObj="chatObj"
    :meetingId="meetingId" ></chat>
  </div>
</template>
<script>
import { setFullscreen, exitFullScreen } from "@/utils/utils.js";
import rtc from "./mixins/rtc.js";
import asr from "./mixins/asr.js";
// import shareRtc from "./mixins/share-rtc.js";
import AudioIcon from "./common/icons/Audioicon.vue";
import AudioIconUser from "./common/icons/AudioiconUser.vue";
import VideoMediaIcon from "./common/icons/VideoMediaicon.vue";
import InvitePerson from "@/view/modal/InvitePerson";
import setting from "@/view/modal/setting.vue";
import translate from "@/view/modal/translate.vue";
import chat from "@/view/modal/chat.vue";
import BackgroundSetting from "@/view/modal/backgroundSetting.vue";
import TRTC from "trtc-sdk-v5";
import {
  getAttendeeList,
  endMeeting,
  setPersonnelStatus,
  muteAll,
  getMeetingFiles,
  getAllTranslateList,
  configUserMeetingTeanslation,
  exportMinutesRecevieCore,
  getMeetingTranslators,
  getMeetingTranslate,
  beginMeetingVideoRecord,
  endMeetingVideoRecord,
  recordingScreenSharing,
} from "@/api/meeting";

import { mapActions } from "vuex";

const queryParam = {
  Key: "",
  SkipCount: 0,
  MaxResultCount: 999,
  Name: "",
  ClassroomId: null,
  DepartmentParentId: null,
};
//  0 文本 1 word 2 pdf
const docType = [
  {
    type: 0,
    name: "导出.txt",
  },
  {
    type: 1,
    name: "导出.word",
  },
  {
    type: 2,
    name: "导出.pdf",
  },
];
const initArr = [
  {
    phoneticTranscriptionType: "16k_zh",
    phoneticTranscriptionTypeName: "无翻译",
  },
];
export default {
  mixins: [rtc, asr],
  props: {
    meetingId: String,
    sdkAppId: Number,
    secretKey: String,
    userSig: String,
    userId: String,
    roomId: String,
    appSecretKey: Object,
  },
  components: {
    AudioIcon,
    AudioIconUser,
    VideoMediaIcon,
    InvitePerson,
    setting,
    translate,
    BackgroundSetting,
    chat,
  },
  data() {
    return {
      cameraId: "",
      microphoneId: "",
      //选中的流
      chooseStream: "",
      //是否为全屏状态
      isFullScreen: false,
      showSidebar: false, //侧边内容
      showFileBar: false,
      FileList: [],
      //选中的人员
      selecetedPeople: "",
      activepeople: "first",
      WebSocket: "", //websocket连接
      peopleKey: "",
      userLists: [],
      //录制
      mediaRecorder: "",
      recordedChunks: [],
      isRecording: false,
      timestampInterval: null,
      timestamp: 0,
      token: "",
      allTranslateList: [], //所有翻译人员
      inAWordObj: {},
      dialogVisible: false,
      docType,
      meetingTranslators: [],
      exportType: 0, //0 文本 1 word 2 pdf
      languageType: "16k_zh", //下载语言类型
      translateType: "16k_zh", //选择翻译语言类型
      translateFullList: [],
      initArr,
      hasError: false, // 状态变量，用于跟踪是否发生错误
      chatObj: {},
      unfoldStatus:false,
    };
  },
  beforeDestroy() {
    navigator.mediaDevices.removeEventListener(
      "devicechange",
      this.getDeviceList
    );
    // 关闭向后台发送ping计时器
    this.stopWebSocketPing();
    if (this.timestampInterval) {
      clearInterval(this.timestampInterval);
    }
  },
  destroyed() {
    if (this.WebSocket) {
      this.WebSocket.close();
    }
    // 主流或者翻译
    if (
      this.meetingInfo.dataType == 101 ||
      this.meetingInfo.isMainStream == 1
    ) {
      this.stopASR();
    }
    this.handleLeave();
  },
  computed: {
    formattedTimestamp() {
      return this.formatTime(this.timestamp);
    },
  },
  watch: {
    // 监听 meetingInfo 的变化
    meetingInfo(newVal, oldVal) {
      // 当 meetingInfo 发生变化时，调用 方法
      try {
        if (
          newVal.businessDataDto.voiceStatus !=
          oldVal.businessDataDto.voiceStatus
        ) {
          if (newVal.businessDataDto.voiceStatus == 0) {
            this.closeVoice();
          }
        }
        //判断主流
        if (newVal.isMainStream != oldVal.isMainStream) {
          if (newVal.isMainStream == 1) {
          } else if (newVal.isMainStream == 0) {
          }
        }
      } catch {}
    },
    speakerId(val) {
      this.switchDevice("speaker", val);
    },
    microphoneId(val) {
      this.switchDevice("audio", val);
    },
    // 选择翻译，开启对应翻译，关闭旧翻译
    async translateType(newVal, oldVal) {
      // console.log("旧翻译：" + oldVal + ",新翻译：" + newVal);
      this.translateFullList = [];
      this.translateFullList = await this.getListFun();
      this.translateFullList.forEach((item) => {
        if (item.phoneticTranscriptionType == oldVal) {
          if (item.userCode == undefined) {
            // 旧翻译为主流，关闭主流
            // console.log("旧翻译为主流，静音主流" + this.bigScreenId);
            this.trtc.muteRemoteAudio(this.bigScreenId, true);
          } else {
            // console.log("旧翻译为翻译" + item.userCode + "，静音翻译");
            this.trtc.muteRemoteAudio(item.userCode, true);
          }
        } else if (item.phoneticTranscriptionType == newVal) {
          if (item.userCode == undefined) {
            // 新翻译为主流，开启主流
            // console.log("新翻译为主流，开启主流声音" + this.bigScreenId);
            this.trtc.muteRemoteAudio(this.bigScreenId, false);
          } else {
            // console.log("新翻译为翻译" + item.userCode + "，开启翻译声音");
            this.trtc.muteRemoteAudio(item.userCode, false);
          }
        } else {
        }
      });
    },
    bigScreenId() {
      // if (this.isRecording) {
      //   //大屏发生变化，重新录制
      //   // setTimeout(() => {
      //   this.recordStart();
      //   // }, 500);
      // }
    },
  },
  created() {
    this.token = localStorage.getItem("storage_key");
  },

  methods: {
    ...mapActions([
      "setCameraList",
      "setSpeakerList",
      "setMicrophoneList",
      "setCurrentSpeakerId",
      "setCurrentMicrophoneId",
      "setCurrentCameraId"
    ]),
    updateMeetingInfo(new_meeting_info) {
      if (!this.WebSocket) {
        this.createWebSocket(new_meeting_info);
        this.init();
      } else {
      }
      if (this.timestampInterval) {
        clearInterval(this.timestampInterval);
      }
      this.timestamp = Number(new_meeting_info.meetingTimeStamp);
      this.timestampInterval = setInterval(() => {
        this.timestamp += 1; // 增加1秒
      }, 1000);
      if (this.meetingInfo.dataType != 401) {
        this.getAllTranslateListFun();
      }
      // 获取录制状态 RecordStatus 1 正在录制
      this.isRecording = this.meetingInfo.recordStatus == 1 ? true : false;
    },
    getAllTranslateListFun() {
      getAllTranslateList({ MeetingId: this.meetingId, key: "" }).then(
        (res) => {
          this.allTranslateList = res;
        }
      );
    },
    init() {
      const handleMedia = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });
          this.stopCamera = () => {
            if (stream) {
              stream.getTracks().forEach((track) => {
                track.stop();
              });
            }
          };
          await this.getDeviceList();
          await this.connectMeeting();
          this.hasError = false;
        } catch (err) {
          this.hasError = true;
          // 分别处理各种错误类型
          switch (err.name) {
            case "NotFoundError":
            case "DevicesNotFoundError":
              // 处理找不到设备的错误
              this.$notify({
                message: "设备未找到",
                type: "info",
              });
              break;
            case "NotReadableError":
            case "TrackStartError":
              // 处理设备无法读取或轨道启动错误
              this.$notify({
                message: "设备无法读取或轨道启动错误",
                type: "info",
              });
              break;
            case "OverconstrainedError":
            case "ConstrainNotSatisfiedError":
              // 处理约束不满足的错误
              this.$notify({
                message: "约束不满足",
                type: "info",
              });
              break;
            case "NotAllowedError":
            case "PermissionDeniedError":
              // 处理权限被拒绝的错误
              this.$notify({
                message: "权限被拒绝",
                type: "info",
              });
              // 这里可以添加重试逻辑或者提示用户
              break;
            case "TypeError":
              // 处理类型错误
              this.$notify({
                message: "类型错误",
                type: "info",
              });
              break;
            default:
              // 处理其他未知错误
              console.error("未知错误", err);
          }
        } finally {
          // 无论成功或失败，都尝试获取设备列表和连接会议
          // 但这里应当考虑是否需要等待它们完成，或者处理可能的错误
        }
      };
      // 调用处理媒体函数
      handleMedia();
      // 使用箭头函数来确保this的上下文正确
      navigator.mediaDevices.addEventListener("devicechange", () =>
        this.getDeviceList()
      );
    },
    // 向后台发送ping
    startWebSocketPing() {
      this.pingInterval = setInterval(() => {
        this.sendMessage("ping");
      }, 30000);
    },
    stopWebSocketPing() {
      if (this.pingInterval) {
        clearInterval(this.pingInterval);
      }
    },
    // 创建一个websocket连接
    createWebSocket(meetingInfo) {
      // let token = localStorage.getItem("storage_key");
      // if (!token) {
      //   return null;
      // }
      // console.log(meetingInfo);
      let businessDataId = this.token
        ? this.meetingInfo.businessDataDto.id
        : null;
      let userDateType = this.token ? null : 1;
      let ws = new WebSocket(
        // `ws://192.168.50.250:8022/api/app/meeting-core/socket-connection/${this.meetingId}`,
        // 本地ws 线上wss
        // process.env.VUE_APP_WS +`/ws?meetingId=${this.meetingId}&businessDataId=${businessDataId}&userDateType=${userDateType}`
         `${window._config.apiWebSocket}/ws?meetingId=${this.meetingId}&businessDataId=${businessDataId}&userDateType=${userDateType}`
        // `ws://localhost:44357/ws?meetingId=${this.meetingId}&businessDataId=${meetingInfo.businessDataDto.id}`,
        // [token]
      );
      ws.onopen = () => {
        // console.log("websocket连接成功");
        //  this.startWebSocketPing();
      };
      ws.onmessage = (e) => {
        // console.log("ws 接收到信息：", e);
        let data = {};
        try {
          data = JSON.parse(e.data);
        } catch (error) {}
        if (data.OperationType == 0) {
          //系统通知
          if (data.SystemValue == 2) {
            this.getPeopleList();
            this.$emit("meetingInfo");
          } else if (data.SystemValue == 1) {
            // 人员更新
            this.getPeopleList();
            this.$emit("meetingInfo");
          } else if (data.SystemValue == 3) {
            //会议结束
            this.handleLeave();
          } else if (data.SystemValue == 101) {
            // 主流信息更新
            // console.log(data.Msg);
            this.$notify({
              message: data.Msg,
              type: "info",
            });
            this.getPeopleList();
            this.$emit("meetingInfo");

            // this.bigScreenAudioTrack()
            // if (this.meetingInfo.isMainStream == 1){

            // }
          } else if (data.SystemValue == 102) {
            // 全员静音
            // if (this.meetingInfo.dataType != 2) {
            // 关掉麦克风
            // if (this.localStream) {
            this.muteAudio();
            this.isMutedAudio = true;
            // }
            // }
            this.sendMessage(data.Msg);
            this.$emit("meetingInfo");
          } else {
          }
        } else if (data.OperationType == 1) {
          //用户设置通知
          // this.getPeopleList();
          // 0:用户启用语音 1·用户停用语音 2:用户启用摄像头 3用户停用摄像头4 用户语音权限开启5 用户语音权限关闭 6用户摄像头权限开启7用户摄像头权限关闭 8用户主流权限开启 9 用户主流权限关闭
          if (data.UserValue == 4) {
          } else if (data.UserValue == 5) {
            this.muteAudio();
            this.isMutedAudio = true;
          } else if (data.UserValue == 6) {
          } else if (data.UserValue == 7) {
            this.muteVideo();
            this.isMutedVideo = true;
          } else if (data.UserValue == 8) {
          } else {
          }
          this.$notify({
            message: data.Msg,
            type: "info",
          });
          this.getPeopleList();
          this.$emit("meetingInfo");
        } else if (data.OperationType == 301) {
          // 语音转写
          let msgObj = JSON.parse(data.Msg);
          // console.log(msgObj, "data123456");
          this.inAWordObj = {
            meetingTimeStamp: msgObj.MeetingTimeStampShow,
            text: msgObj.Text,
            userCode: msgObj.UserCode,
          };
        } else if (data.OperationType == 401) {
          if (data.Result) {
            this.$notify({
              message: data.Msg,
              type: "info",
            });
          } else {
            this.stopRecord();
            this.$notify({
              message: "录制失败",
              type: "info",
            });
          }
        } else if (data.OperationType == 202) {
          this.chatObj={ msg: data.Msg,
             userName: data.User ,
             userCode:data.UserCode}
          // this.chatlist.push({ msg: data.Msg, userName: data.User });
        }
      };
      ws.onclose = (event) => {
        if (event.wasClean) {
          // console.log("websocket连接正常关闭");
        } else {
          // console.log("websocket连接异常关闭");
        }
      };
      ws.onerror = () => {
        // console.log("websocket连接错误");
      };
      this.WebSocket = ws;
    },
    sendMessage(message) {
      if (this.WebSocket.readyState === WebSocket.OPEN) {
        this.WebSocket.send(message);
      } else {
        console.error("无法发送消息，WebSocket连接未打开");
      }
    },
    //选中流放大
    // handleChoose(e) {
    //     if (this.isFullScreen) {
    //         return
    //     }
    //     this.chooseStream = e
    //     const dom = document.getElementById(e);
    //     if (dom) {
    //         this.isFullScreen = setFullscreen(dom)
    //     }
    // },
    // handleCloseFullScreen() {
    //     if (this.isFullScreen) {
    //         exitFullScreen()
    //         this.$nextTick(() => {
    //             this.isFullScreen = false
    //         })
    //         console.log(this.isFullScreen)
    //     }
    // },
    toggleScreen(e) {
      if (this.isFullScreen) {
        exitFullScreen();
        this.isFullScreen = false;
      } else {
        this.chooseStream = e;
        const dom = document.getElementById(e);
        if (dom) {
          this.isFullScreen = setFullscreen(dom);
        }
      }
    },
    async connectMeeting() {
      if (!this.sdkAppId || !this.userId || !this.roomId || !this.userSig) {
        alert(`缺少参数`);
        return;
      }
      await this.enterRoom(); //进入房间
      if (this.meetingInfo.dataType != 401) {
        this.handleStartLocalAudio();
        this.handleStartLocalVideo();
      }
    },
    async getDeviceList() {
      const cameraList = await TRTC.getCameraList();
      const speakerList = await TRTC.getSpeakerList();
      if (cameraList[0]) {
        this.cameraId = cameraList[0].deviceId;
        this.setCurrentCameraId(cameraList[0].deviceId);
      }
      if (speakerList[0]) {
        this.speakerId = speakerList[0].deviceId;
        this.setCurrentSpeakerId(speakerList[0].deviceId);
      }

      const microphoneList = await TRTC.getMicrophoneList();
      if (microphoneList[0]) {
        this.microphoneId = microphoneList[0].deviceId;
        this.setCurrentMicrophoneId(microphoneList[0].deviceId);
      }

      this.setCameraList(cameraList);
      this.setSpeakerList(speakerList);
      this.setMicrophoneList(microphoneList);
    },
    handleEnd() {
      endMeeting({ meetingId: this.meetingId }).then((res) => {
        this.handleLeave();
      });
    },
    async handleLeave() {
      if (!this.hasError) {
        this.stopCamera();
        await this.exitRoom();
      }
      if (this.timestampInterval) {
        clearInterval(this.timestampInterval);
      }
      this.$router.push("home");
    },
    // 选中用户
    selectPerson(user) {
      if (this.meetingInfo.dataType == 1 || this.meetingInfo.dataType == 2) {
        if (this.selecetedPeople == user.id) {
          this.selecetedPeople = "";
        } else {
          this.selecetedPeople = user.id;
        }
      }
    },
    // 用户控制
    userControl(type, item) {
      let data = {};
      if (type == "voice") {
        data = {
          id: item.id,
          status: 1, //0 用户参会状态 1 语音状态 2 摄像头状态 3 是否主流
          statusValue: item.voiceAuthority == 0 ? 1 : 0, //0 禁用 1 启用
        };
      } else if (type == "video") {
        data = {
          id: item.id,
          status: 2, //0 用户参会状态 1 语音状态 2 摄像头状态 3 是否主流
          statusValue: item.cameraAuthority == 0 ? 1 : 0, //0 禁用 1 启用
        };
      } else if (type == "mainScreen") {
        data = {
          id: item.id,
          status: 3, //0 用户参会状态 1 语音状态 2 摄像头状态 3 是否主流
          statusValue: 1, //0 禁用 1 启用
        };
      } else {
      }
      setPersonnelStatus(data).then((res) => {
        // if(data.status==3){
        this.$emit("meetingInfo");
        this.showSidebar = false;
        // }
        // this.getPeopleList();
        // this.handleClose()
      });
    },
    closeVoice() {
      if (this.localStream) {
        if (!this.isMutedAudio) {
          this.sendMessage("用户停用语音");
          this.localStream.muteAudio();
          this.isMutedAudio = true;
        }
      }
    },

    //音量开关
    volumeSwitch() {
      if (
        this.meetingInfo.voiceAuthority == 1 ||
        (this.meetingInfo.voiceAuthority == 0 && this.isMutedAudio == false)
      ) {
        if (this.isMutedAudio) {
          //打开音频
          this.sendMessage("用户启用语音");
          this.unmuteAudio();
          this.isMutedAudio = false;
          if (
            this.meetingInfo.isMainStream ||
            this.meetingInfo.dataType == 101
          ) {
            this.initASR();
          }
        } else {
          this.sendMessage("用户停用语音");
          this.muteAudio();
          this.isMutedAudio = true;
        }
      } else {
        this.$notify({
          message: "暂无开启语音权限",
          type: "info",
        });
      }
    },
    //摄像头开关
    videoSwitch() {
      //判断是否是主屏流
      if (
        (this.meetingInfo &&
          this.meetingInfo.isMainStream &&
          this.meetingInfo.isMainStream === 1) ||
        this.meetingInfo.cameraAuthority == 1 ||
        (this.meetingInfo.cameraAuthority == 0 && this.isMutedVideo == false)
      ) {
        if (this.shareStatus == "shared") {
          this.$notify({
            message: "正在屏幕分享中",
            type: "info",
          });
          return;
        }
        if (this.isMutedVideo) {
          //打开视频
          this.sendMessage("用户启用摄像头");
          this.isMutedVideo = false;
          this.unmuteVideo();
          // if (this.isRecording) {
          //   this.recordStart();
          // }
        } else {
          //关闭视频

          this.sendMessage("用户停用摄像头");
          this.isMutedVideo = true;
          this.muteVideo();
        }
      } else {
        this.$notify({
          message: "暂无开启摄像头权限",
          type: "info",
        });
      }
    },
    //开启屏幕分享处理
    async handleStartScreenShare() {
      this.shareStatus = "sharing";
      try {
        await this.trtc.startScreenShare({ view: "share_main" });
        this.hasShareStream = true;
        this.shareStatus = "shared";
        let data = {
          meetingId: this.meetingId,
          mediaId: 1, //0 主流 1 辅流
        };
        recordingScreenSharing(data).then((res) => {});
        this.addSuccessLog("Start share screen success");
      } catch (error) {
        this.shareStatus = "stopped";
        this.addFailedLog(`Start share error: ${error.message}`);
      }
    },
    //关闭屏幕分享处理
    async handleStopScreenShare() {
      // 判断是否在录制中，录制中需要中断视频录制
      // if (this.isRecording) {
      //   this.stopRecord();
      // }
      if (this.shareStatus !== "shared") {
        this.addFailedLog("The Share is not started");
        return;
      }
      this.shareStatus = "stopping";
      try {
        await this.trtc.stopScreenShare();
        this.hasShareStream = false;
        this.shareStatus = "stopped";
        let data = {
          meetingId: this.meetingId,
          mediaId: 0, //0 主流 1 辅流
        };
        recordingScreenSharing(data).then((res) => {});
        this.addSuccessLog("Stop share screen success");
      } catch (error) {
        this.shareStatus = "shared";

        this.addFailedLog(`Stop share error: ${error.message}`);
      }
    },
    //屏幕分享开关
    shareSwitch() {
      // if(this.shareStatus = "shared"){
      //   return
      // }
      //判断是否是主屏流
      if (
        this.meetingInfo &&
        this.meetingInfo.isMainStream &&
        this.meetingInfo.isMainStream === 1
      ) {
        // if (!this.isMutedVideo) {
        //   this.muteVideo();
        //   this.isMutedVideo = true;
        // }
        this.handleStartScreenShare();
      } else {
        this.$notify({
          message: "您当前不是主流",
          type: "info",
        });
      }
    },
    //关闭屏幕分享
    closeShareVideo() {
      this.handleStopScreenShare();
    },
    async peopleSwitch() {
      await this.getPeopleList();
      this.showFileBar = false;
      this.showSidebar = !this.showSidebar;
    },
    handlePeopleClose() {
      this.showSidebar = false;
    },
    //请求人员信息
    async getPeopleList(key) {
      if (!this.token) {
        return null;
      }
      let p = {
        meetingId: this.meetingId,
        key: key,
      };
      await getAttendeeList(p).then((res) => {
        this.userLists = res;
        // console.log(this.userLists,'9998888')
      });
    },
    //邀请
    inviteSwitch() {
      this.$refs.inviteDialog.showModal(this.meetingId);
    },
    recordStart() {
      try {
        let data = {
          meetindId: this.meetingId,
          status: 0, //0 开始录制 1 停止录制 101 查询录制状态
        };
        beginMeetingVideoRecord(data)
          .then((res) => {
            this.isRecording = true;
          })
          .catch(() => {});
        // let videoTrack = null;
        // let audioTrack = null;
        // const shareMainContainer = this.$refs.share_main;
        // if (shareMainContainer) {
        //   const videoElement = shareMainContainer.querySelector('video');
        //   if (videoElement) {
        //     // 在这里可以操作videoElement
        //     console.log('找到video元素', videoElement);
        //     const stream = videoElement.captureStream();
        //     audioTrack = stream.getAudioTracks()[0];
        //     videoTrack = stream.getVideoTracks()[0];
        //   } else {
        //     console.log('在share_main中没有找到video元素');
        //   }
        // } else {
        //   console.log('没有找到share_main元素');
        // }
        // const bigMainContainer = this.$refs.big_main;
        // if (!videoTrack&&bigMainContainer) {
        //   const videoElement = bigMainContainer.querySelector('video');
        //   if (videoElement) {
        //     // 在这里可以操作videoElement
        //     console.log('找到video元素', videoElement);
        //     const stream = videoElement.captureStream();
        //     audioTrack = stream.getAudioTracks()[0];
        //     videoTrack = stream.getVideoTracks()[0];
        //   } else {
        //     console.log('在big_main中没有找到video元素');
        //   }
        // } else {
        //   console.log('没有找到big_main元素');
        // }
        // if (this.mediaRecorder && this.isRecording) {
        //   //如果正在录制，就停止
        //   // this.isRecording = false;
        //   this.mediaRecorder.stop();
        // }
        // // 获取视频轨道、音频轨道
        // let record_userId = this.bigScreenId;
        // if (this.userId == this.meetingInfo.mainStreamUser) {
        //   //本人是主屏,不需要传userId
        //   record_userId = "";
        // }
        // console.log("record_userId", record_userId);
        // let videoTrack = null;
        // let audioTrack = null;
        // let streamType = TRTC.TYPE.STREAM_TYPE_MAIN; //默认录制摄像头视频
        // if (this.shareStatus == "shared" || this.bigScreenUser.hasShareScreen) {
        //   //有屏幕分享,无论本地还是远端用户
        //   streamType = TRTC.TYPE.STREAM_TYPE_SUB;
        // }
        // videoTrack = this.trtc.getVideoTrack({
        //   userId: record_userId,
        //   streamType: streamType,
        // });
        // // 获取音频轨道文档 https://web.sdk.qcloud.com/trtc/webrtc/v5/doc/zh-cn/TRTC.html#getAudioTrack
        // audioTrack = this.trtc.getAudioTrack({
        //   userId: record_userId,
        //   streamType: record_userId ? streamType : TRTC.TYPE.STREAM_TYPE_MAIN,
        // });
        // //如果本地用户分享屏幕，音频可能要获取两个，一个是麦克风，一个是屏幕分享的声音，
        // //上边只获取了屏幕分享的声音，屏幕分享的声音是否有麦克风的声音，需要实际录制测试
        // // 创建一个新的MediaStream并添加轨道

        // const mediaStream = new MediaStream();
        // if (videoTrack) {
        //   mediaStream.addTrack(videoTrack);
        // }
        // if (audioTrack) {
        //   mediaStream.addTrack(audioTrack);
        // }
        // // this.mediaRecorder = null;
        // this.mediaRecorder = new MediaRecorder(mediaStream);
        // this.mediaRecorder.ondataavailable = (event) => {
        //   if (event.data.size > 0) {
        //     this.recordedChunks.push(event.data);
        //   }
        // };
        // this.mediaRecorder.onstop = () => {
        //   if (!this.isRecording) {
        //     this.saveRecordFile();
        //   }
        // };
        // // this.mediaRecorder.onstop = () => {
        // //   //创建blob对象
        // //   const blob = new Blob(this.recordedChunks, {
        // //     type: "video/webm",
        // //   });
        // //   const url = URL.createObjectURL(blob);
        // //   let downloadLink = document.createElement("a");
        // //   downloadLink.href = url;
        // //   downloadLink.download = "recorded-video.webm";
        // //   downloadLink.style.display = "none";
        // //   document.body.appendChild(downloadLink);
        // //   downloadLink.click();
        // //   document.body.removeChild(downloadLink);
        // // };
        // // this.recordedChunks = [];
        // this.mediaRecorder.start();
        // this.isRecording = true;
      } catch (e) {
        console.error(e);
      }
    },
    stopRecord() {
      let data = {
        meetindId: this.meetingId,
        status: 1, //0 开始录制 1 停止录制 101 查询录制状态
      };
      endMeetingVideoRecord(data)
        .then((res) => {
          this.isRecording = false;
          this.$notify({
            message: "录制结束，请从课程列表中查看或下载",
            type: "info",
          });
        })
        .catch(() => {});
      // this.isRecording = false;
      // this.mediaRecorder.stop();
      // this.$notify({
      //   message: "录制已结束",
      //   type: "info",
      // });
    },
    saveRecordFile() {
      //创建blob对象
      const blob = new Blob(this.recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      let downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "recorded-video.webm";
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async fileSwitch() {
      await this.getFileList();
      this.showSidebar = false;
      this.showFileBar = !this.showFileBar;
    },
    async getFileList(key) {
      let p = {
        meetingId: this.meetingId,
        key: key,
      };
      await getMeetingFiles(p).then((res) => {
        this.FileList = res;
      });
    },
    virtualBackgroundSwitch() {
      this.$refs.virtualBackground.showModal(this.meetingId);
    },
    settingSwitch() {
      this.$refs.setDialog.showModal();
    },
    handleAllMute() {
      muteAll({ meetingId: this.meetingId }).then((res) => {
        this.getPeopleList();
        this.$emit("meetingInfo");
      });
    },
    handleSearch() {
      this.getPeopleList(this.peopleKey);
    },
    downloadFile(file) {
      // 创建一个a元素
      const a = document.createElement("a");
      // 设置href属性为提供的URL
      a.href = file.url;
      // 设置download属性为提供的文件名
      a.download = file.name;
      // 将a元素添加到文档中，并触发点击事件
      document.body.appendChild(a);
      a.click();
      // 移除a元素
      document.body.removeChild(a);
    },
    muteUser() {},
    setMainUser() {},
    kickUser() {},

    //显示成功的弹窗提示
    addSuccessLog(log) {},
    addFailedLog(log) {},
    //性能监控暂无 https://cloud.tencent.com/document/product/1464/58553
    reportSuccessEvent() {},
    reportFailedEvent() {},

    // 人员弹框
    // handleClose(done) {
    //   done();
    // },
    // 文件弹框
    handleFileClose(done) {
      done();
    },
    // 调整音量
    handleSpeakerVolume(volume) {
      this.setSpeakerVolume(volume);
    },
    // 时间
    formatTime(timestamp) {
      const hours = Math.floor(timestamp / 3600);
      const minutes = Math.floor((timestamp % 3600) / 60);
      const seconds = timestamp % 60;
      // 将时分秒格式化为两位数
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    // 语音转写
    //虚拟背景
    handleSettingBg(options) {
      const { active, url } = options;
      if (active == 1) {
        // 不使用虚拟背景
        if (this.virtualBackgroundEnabled) {
          this.virtualBackgroundEnabled = false;
          this.disableVirtualBackground();
        } else {
        }
      } else if (active == 2) {
        // 模糊背景
        if (!this.virtualBackgroundEnabled) {
          this.handleStartVirtualBackground("blur", "");
          this.virtualBackgroundEnabled = true;
        } else {
          this.updateVirtualBackground("blur", "");
        }
      } else {
        // 背景图
        if (url) {
          // 得到图片地址
          if (!this.virtualBackgroundEnabled) {
            this.handleStartVirtualBackground("image", url);
            this.virtualBackgroundEnabled = true;
          } else {
            this.updateVirtualBackground("image", url);
          }
        }
      }
    },
    // 语音转写
    translateSwitch() {
      this.$refs.translate.onShow();
      let data = {
        meetingId: this.meetingId,
        type: "16k_zh",
      };
      configUserMeetingTeanslation(data).then((res) => {});
      // this.startLangASR('16k_zh');
    },
    // 切换翻译
    handleLang(type) {
      this.translateType = type;
      let data = {
        meetingId: this.meetingId,
        type: type,
      };
      configUserMeetingTeanslation(data).then((res) => {});
      // this.startLangASR(type);
    },
    // 关闭语音转写
    // closeOk() {
    //   this.stopASR();
    // },
    async onShowMeeting() {
      this.dialogVisible = true;
      await getMeetingTranslators({ MeetingId: this.meetingId }).then((res) => {
        return (this.meetingTranslators = res);
      });
    },
    handleDownLoad() {
      let data = {
        meetingReserveId: this.meetingId,
        languageType: this.languageType,
        exportType: this.exportType,
      };
      exportMinutesRecevieCore(data).then((res) => {
        const baseFileName = this.meetingInfo.meetingTitle;
        const fileName =
          this.exportType == 1
            ? baseFileName + ".docx"
            : this.exportType == 2
            ? baseFileName + ".pdf"
            : baseFileName + ".txt";
        this.exportFileFun(res, fileName);
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    exportFileFun(res, fileName) {
      const content = res;
      // const blob = new Blob([content]);
      let fileType;
      if (fileName.endsWith(".docx")) {
        fileType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (fileName.endsWith(".pdf")) {
        fileType = "application/pdf";
      } else {
        fileType = "text/plain";
      }
      const blob = new Blob([res], { type: fileType });

      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        // this.$notify({
        //   message: '下载成功',
        //   type: "info",
        // });
        this.handleClose();
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    getListFun() {
      return new Promise((resolve, reject) => {
        let translateList = this.clone(this.initArr);
        getMeetingTranslate({ MeetingId: this.meetingId })
          .then((res) => {
            translateList = translateList.concat(res.translateMeeting);
            resolve(translateList);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 聊天
    chatSwitch() {
      this.$refs.chat.onShow();
      // let data = {
      //   meetingId: this.meetingId,
      //   type: "16k_zh",
      // };
      // configUserMeetingTeanslation(data).then((res) => {});
      // this.startLangASR('16k_zh');
    },
    // 提交聊天内容
    handleSend(val) {
      this.sendMessage("KTXX202:" + val);
    },
    //打开右边框
    handleUnfold(){
      this.unfoldStatus=!this.unfoldStatus;
    },
    // 切换麦克风
    async changeSetMic(id){
      const microphoneList = await TRTC.getMicrophoneList();
      const hasId = microphoneList.some(mic => mic.deviceId ==id);
      if (hasId) {
        await this.trtc.updateLocalAudio({ option: { microphoneId: id }});
        this.microphoneId = id;
        this.setCurrentMicrophoneId(id);
      }
    },
    // 切换摄像头
    async changeSetCamera(id){
      const cameraList = await TRTC.getCameraList();
      const hasId = cameraList.some(camera => camera.deviceId ==id);
      if (hasId) {
        await this.trtc.updateLocalVideo({ option: { cameraId: id,fillMode:'contain' }});
        this.cameraId = id;
        this.setCurrentCameraId(id);
      }
    }
  },
};
</script>
<style lang="less" scoped>
@import "./css/meeting-room.less";
</style>
